import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { delay } from "redux-saga/effects";

import {
  getAccountSettings,
  getCreditVetProcessAttempted,
} from "../account/selectors";
import { getContractLength, getMobileDCOrderParams } from "./selectors/order";
import { getAccountId, getLeadId, getOrderId } from "../order/selectors";

import * as ProductAPI from "../../api/v1/product";
import * as AccountAPI from "../../api/v1/account";
import * as GuidedSalesAPI from "../../api/v1/guidedSales";
import * as MobileAPI from "../../api/v1/mobile";
import * as SIMsAPI from "../../api/v1/sim";
import * as MobileProvisioningAPI from "../../api/v1/mobileProvisioning";
import { RESIGN_WITH_CHANGES } from "./constants";
import {
  getConfigProperties,
  getProductComponentData,
  getProductDataForConfig,
} from "./selectors/productConfig";

import { fetchCreditVetStatus } from "../../store/account/actions";

/**
 * Set mobile contract length in months for all mobile products
 * @param contractLength
 * @param date
 * @returns {{type: string, contractLength: *, date: *}}
 */
export const setContractLengthAllMobileProducts = createAsyncThunk(
  "mobile/setContractLengthAllMobileProducts",
  async ({ contractLength, date }: any, { getState }) => {
    const canSelectCommission =
      getAccountSettings(getState()).as_commission_type_selection_for_mobile ===
      "1";
    return {
      contractLength,
      date,
      canSelectCommission,
    };
  }
);

/**
 * Set the filterValue in months for the UI to use as a filter
 * @param contractLength
 * @returns {{type: string, contractLength: *}}
 */
export const setContractLengthUIFilter = createAction(
  "mobile/setContractLengthUIFilter",
  (filterValue: number) => ({
    payload: filterValue,
  })
);

/**
 * Toggle mobile co-terminus contract type
 * @returns {{type: string}}
 */
export const toggleCoTerminus = createAction("mobile/toggleCoTerminus");

/**
 * Get available mobile products
 * @param isDynamic
 * @returns {IterableIterator<*>}
 */
export const fetchMobileSearch = createAsyncThunk(
  "mobile/fetchMobileSearch",
  async (arg: boolean, { getState }) => {
    const accountId = getAccountId(getState());
    const leadId = getLeadId(getState());

    return await ProductAPI.MobileSearch(accountId, arg ? 1 : 0, leadId);
  }
);

interface FetchProductInstancesParams {
  page?: number;
}

/**
 * Get Resign-able product instances
 * @returns {Function}
 */
export const fetchProductInstances = createAsyncThunk(
  "mobile/fetchProductInstances",
  async ({ page = 1 }: FetchProductInstancesParams, { getState, dispatch }) => {
    const accountId = getAccountId(getState());
    const settings = getAccountSettings(getState());

    const servicesType =
      settings.can_access_vf_direct === "1"
        ? "third_party_billing_services"
        : "live_evo_services";
    const params = {
      with: servicesType,
      is_resignable: 1,
      sort: "contract_end_date",
    };
    const response = await ProductAPI.ProductInstance(
      "Mobile",
      accountId,
      page,
      params
    );
    // If there's a next page, do the above again.
    const nextPage = _.get(response, "pagination.next_page");
    if (nextPage && nextPage > page) {
      dispatch(fetchProductInstances({ page: nextPage }));
    }
    return response;
  }
);

/**
 * Find the product used to do resigns without change.
 * @returns {IterableIterator<*>}
 */
export const fetchResignProductSearch = createAsyncThunk(
  "mobile/fetchResignProductSearch",
  async (_) => {
    return await ProductAPI.search({
      has_extra_services: 1,
      extra_services_type: "resign",
    });
  }
);

export const fetchBoltOnSearch = createAsyncThunk(
  "mobile/fetchBoltOnSearch",
  async (_, { getState }) => {
    const accountId = getAccountId(getState());

    return await ProductAPI.AccountBoltOnSearch(accountId);
  }
);

/**
 * Get available account level bolt-ons (saga)
 * @returns {Function}
 */
export const fetchAccountBoltOns = createAsyncThunk(
  "mobile/fetchAccountBoltOns",
  async (_, { getState }) => {
    const accountId = getAccountId(getState());

    return await AccountAPI.AccountLevelBoltOnsNetwork(accountId);
  }
);

/**
 * Set account bolt on
 * @param serviceProviderId
 * @param boltOnType
 * @param value
 * @returns {{type: string, serviceProviderId: *, boltOnType: *, value: *}}
 */
export const setAccountBoltOn = createAction<{
  serviceProviderId: string;
  boltOnType: string;
  value: any;
}>("mobile/setAccountBoltOn");

/**
 * Set optional start date from ALBs
 * @param date
 * @returns {{type: string, date: *}}
 */
export const setAccountBoltOnStartDate = createAction<{
  date: any;
}>("mobile/setAccountBoltOnStartDate");

interface SetProductQuantityPayload {
  quantity: number | undefined;
  productId: string;
}

/**
 * Set product quantity
 * @param quantity
 * @param productId
 * @returns {{type: string, qty: *, productId: *, settings: *}}
 */
export const setProductQuantity = createAsyncThunk(
  "mobile/setProductQuantity",
  async (arg: SetProductQuantityPayload, { getState, dispatch }) => {
    const state = getState();
    const settings = getAccountSettings(state);
    const isOneMonthContract = getContractLength(state) === 1;

    // Refresh Credit Vet if it has been performed previously (i.e. user has returned to step 1 to change things)
    // TP59410
    if (getCreditVetProcessAttempted(state)) {
      dispatch(fetchCreditVetStatus());
    }

    return {
      ...arg,
      settings,
      isOneMonthContract,
    };
  }
);

/**
 * Remove product configs.
 * @param configIndexes
 * @returns {{type: string, id: *}}
 */
export const removeConfig = createAsyncThunk(
  "mobile/removeConfig",
  async ({ configIds }: { configIds: string[] }, { dispatch, getState }) => {
    // Check if the credit vet process has been attempted
    if (getCreditVetProcessAttempted(getState())) {
      // Dispatch fetch credit vet status action
      dispatch(fetchCreditVetStatus());
    }
    return configIds;
  }
);

/**
 * Clear all product configs.
 * @returns {{type: string, id: *}}
 */
export const clearConfig = createAction("mobile/clearConfig");

/**
 * Set Resign Type
 * @param {array} resignIds - search param for configs to act upon
 * @param {string} resignType
 * @returns {{configIndices: *, resignType: *}}
 */
export const setResignType = createAction<{
  resignIds: string[];
  resignType: string;
}>("mobile/setResignType");

/**
 * Set Resign Product (for "resign with change" orders)
 * @param {array} resignIds - configs to act upon
 * @param {string | boolean} productId
 * @returns {{configIndices: *, productId: *}}
 */
export const setResignProduct = createAction<{
  resignIds: string[];
  productId: string | false;
}>("mobile/setResignProduct");

/**
 * Remove resign configs by instance ID
 * @param {array} resignIds
 * @returns {{type: string, resignIds: *}}
 */
export const removeResign = createAction<{ resignIds: string[] }>(
  "mobile/removeResign"
);

/**
 * Get all mobile product data required for configs (going into step 2) (saga)
 * @param {boolean} force
 * @returns {Function}
 */
export const requestAllMobileProductData =
  (force: boolean) => (dispatch: any, getState: any) => {
    const productIds = _.uniq(
      getState().mobile.configs.map((c: any) => c.productId)
    );
    const productData = getState().mobile.productData;
    productIds.forEach((productId: any) => {
      if (
        (productId &&
          _.get(productData, `${productId}.response.status`) !== "success") ||
        force
      ) {
        dispatch(requestMobileProductData(productId));
      }
    });
  };

export const requestMobileProductData = createAsyncThunk(
  "mobile/requestMobileProductData",
  async (productId: string, { getState }) => {
    const accountId = getAccountId(getState());
    const orderId = getOrderId(getState());

    const response = await GuidedSalesAPI.productData(
      accountId,
      productId,
      false,
      {},
      orderId
    );
    return response;
  }
);

/**
 * Get pricing data for a single mobile config (saga)
 * (for when pricing has been altered in step 2)
 * @param configIds {Array}
 * @returns {Function}
 */
export const requestMobilePricingData = createAsyncThunk(
  "mobile/requestMobilePricingData",
  async ({ configIds }: { configIds: number[] }, { getState }) => {
    const state = getState();
    const accountId = getAccountId(state);
    const responses = await Promise.all(
      configIds.map((configId) =>
        fetchSingleMobilePricingData(state, configId, accountId)
      )
    );
    return { configIds, responses };
  }
);

/**
 * Get pricing data for single mobile config.
 * @see fetchMobilePricingData
 * @param configId
 * @param accountId
 * @returns {IterableIterator<*>}
 */
const fetchSingleMobilePricingData = async (
  state: any,
  configId: number,
  accountId: string
) => {
  const { params, productId } = getMobileDCOrderParams(
    state,
    configId,
    true,
    null
  );
  const orderId = getOrderId(state);
  const response = await GuidedSalesAPI.productData(
    accountId,
    productId,
    false,
    params,
    orderId
  );
  return response;
};

/**
 * Get existing dynamic property values for resigns.
 *
 * The "Resign with Changes" flow can receive pre-existing dynamic property values from product data (like WLR & Broadband)
 * when ProductData is called with the appropriate instance ID (that we get from the initial Product/Instance/Mobile call
 * These should be shown in the config form and used for the final OrderProduct/Create call.
 *
 * Apparently mobile orders can be ~50 products, so full product data responses can't be stored against them all without performance issues.
 * Hence we get just the resign ones here and store just the current_value attrs in each config's `properties` node.
 *
 * Possible values that get populated include (according to @ianc) :
 * mobile_number, connection_type, sim_type, parent_number, corporate_id, pbx_ddi_range_extn_number, is_sim_required,
 * user_name, wwcap_enabled & acquisition_method
 *
 * acquisition_method will be resign if the networks match, or port/mig otherwise
 *
 * @returns {Function}
 */
export const requestAllResignPropertyValues = createAsyncThunk(
  "mobile/requestAllResignPropertyValues",
  async (_, { dispatch, getState }) => {
    const state = getState() as any;
    state.mobile.configs.forEach((config: any, configId: number) => {
      if (config.resignType === RESIGN_WITH_CHANGES) {
        dispatch(
          requestResignPropertyValues({
            configId,
            productId: config.productId,
            resignId: config.resignId,
          })
        );
      }
    });
  }
);

export const requestResignPropertyValues = createAsyncThunk(
  "mobile/requestResignPropertyValues",
  async ({ productId, resignId }: any, { getState }) => {
    const accountId = getAccountId(getState());
    const orderId = getOrderId(getState());

    const response = await GuidedSalesAPI.productData(
      accountId,
      productId,
      resignId,
      {},
      orderId
    );
    return response;
  }
);

/**
 * Requests available CLI bolt on data for all products selected in order. (Saga)
 *
 * Note: Not sure this is the best way to do this...
 * Still using Redux-thunk to access state in the action creator
 * Feels strange to have both thunk & saga middleware....
 * At the same time, see Dan Abramov's answer here:
 * https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
 *
 * Spawning the extra actions in the saga is possible but messy.
 * Perhaps separation of concerns between:
 * saga - async actions
 * +
 * action creators - spawning all those actions
 * is the cleanest way.
 * ...even if we do end up keeping thunk middleware.
 *
 * @returns {Function}
 */
export const requestAllCliBoltOnProducts =
  () => (dispatch: any, getState: any) => {
    const productIds = _.uniq(
      getState().mobile.configs.map((c: any) => c.productId)
    );
    productIds.forEach((productId: any) => {
      if (
        _.get(
          getState().mobile.cliBoltOnSearch[productId],
          "response.products"
        ) ||
        true
      ) {
        dispatch(fetchCliBoltOnProducts(productId));
      }
    });
  };

/**
 * Get available CLI-level bolt-ons for a certain product
 * @param action
 * @returns {IterableIterator<*>}
 */
export const fetchCliBoltOnProducts = createAsyncThunk(
  "mobile/fetchCliBoltOnProducts",
  async (productId: string, { getState, dispatch }) => {
    const accountId = getAccountId(getState());
    const accountSettings = getAccountSettings(getState());

    const response = await ProductAPI.MobileBoltOnSearch(
      "cli",
      productId,
      accountId,
      false, // Is top up bolt on
      1,
      accountSettings.category_id_cli_bolt_ons,
      accountSettings.can_access_vf_direct === "1" ? 1 : undefined
    );
    // If we successfully receive products from the above search, then
    // initiate the search to find the top up bolt ons and append these.
    // These have to be searched for separately.
    // See: https://auroratarget.tpondemand.com/entity/6400-data-top-ups.
    if (response.status === "success") {
      dispatch(fetchCliTopUpBoltOnProducts({ productId: productId }));
    }
    return response;
  }
);

// to avoid TS errors.
interface FetchCliTopUpBoltOnProductsArgs {
  productId: string;
}

interface FetchCliTopUpBoltOnProductsResponse {
  productId: string;
  response: any;
}

export const fetchCliTopUpBoltOnProducts = createAsyncThunk<
  FetchCliTopUpBoltOnProductsResponse,
  FetchCliTopUpBoltOnProductsArgs,
  { state: any }
>("mobile/fetchCliTopUpBoltOnProducts", async ({ productId }, { getState }) => {
  const state = getState();
  const accountId = getAccountId(state);
  const accountSettings = getAccountSettings(state);
  const response = await ProductAPI.MobileBoltOnSearch(
    "cli",
    productId,
    accountId,
    true, // Is top up bolt on
    1,
    accountSettings.category_id_cli_bolt_ons,
    accountSettings.can_access_vf_direct === "1" ? 1 : undefined
  );
  return { productId, response };
});

export const chooseCliBoltOn = createAction(
  "mobile/chooseCliBoltOn",
  (
    configId: number,
    boltOnType: string | undefined,
    boltOnId: string | number,
    slotId?: string
  ) => ({
    payload: { configId, boltOnType, boltOnId, slotId },
  })
);

/**
 * Find the Daisy Fresh product (saga)
 * @returns {Function}
 */
export const fetchDaisyFreshSearch = createAsyncThunk(
  "mobile/fetchDaisyFreshSearch",
  async (_, { getState }) => {
    const accountId = getAccountId(getState());

    const response = await ProductAPI.daisyFreshSearch(accountId, "2");
    return response;
  }
);

/**
 * Set Daisy Fresh values...
 * @param amount
 * @returns {{type: string, amount: *}}
 */
export const setDaisyFreshHardwareCredits = createAction<string>(
  "mobile/setDaisyFreshHardwareCredits"
);

export const setDaisyFreshTerminationFees = createAction<string>(
  "mobile/setDaisyFreshTerminationFees"
);

export const setDaisyFreshEtf = createAction<string>("mobile/setDaisyFreshEtf");

export const setDaisyFreshLimitExceeded = createAction(
  "mobile/setDaisyFreshLimitExceeded"
);

/**
 * Fetch Hardware Credit product
 * @returns {IterableIterator<*>}
 */
export const fetchHardwareCreditProductSearch = createAsyncThunk(
  "mobile/fetchHardwareCreditProductSearch",
  async (_, { getState }) => {
    const accountId = getAccountId(getState());

    const response = await ProductAPI.hardwareCreditSearch(accountId);
    return response;
  }
);

export const updateConfigProperty = createAction<{
  propertyName: string;
  value: any;
  configIds: number[];
  isVfDirect?: boolean;
}>("mobile/updateConfigProperty");

/**
 * Apply an array of values to multiple mobile configurations
 * For the PAC & Mobile No. bulk entry requirements
 */
export const arrayUpdateConfigProperty = createAction<{
  propertyName: string;
  values: string | string[];
  configIds: number[];
}>("mobile/arrayUpdateConfigProperty");

export const validateConfigProperty = createAction<{
  propertyName: string;
  configIds: number[];
  isVfDirect?: boolean;
}>("mobile/validateConfigProperty");

export const validateAllConfigProperties = createAction<boolean>(
  "mobile/validateAllConfigProperties"
);

/**
 * Get reserved numbers
 *
 * @param network
 * @returns {Function}
 */
export const requestReservedNumbersList = createAsyncThunk(
  "mobile/requestReservedNumbersList",
  async (network: string, { getState }) => {
    const accountId = getAccountId(getState());

    const response = await MobileAPI.reservedNumbersList(accountId, network);
    return response;
  }
);

/**
 * Toggle bill cap confirmation checkbox
 * @returns {{type: string}}
 */
export const toggleBillCapConfirmation = createAction(
  "mobile/toggleBillCapConfirmation"
);

/**
 * Toggle DWS Terms checkbox
 * @returns {{type: string}}
 */
export const toggleDwsTermsConfirmation = createAction(
  "mobile/toggleDwsTermsConfirmation"
);

/**
 * Set global resign start date
 * @param _
 * @param date
 * @returns {{date: *, type: *}}
 */
export const setResignStartDate = createAction<string | null | undefined>(
  "mobile/setResignStartDate"
);

export const receivePacVerification = createAction(
  "mobile/receivePacVerification",
  (configId, response, verifiedAcquisitionMethod) => ({
    payload: { response, verifiedAcquisitionMethod, configId },
  })
);

export const setPacVerificationError = createAction(
  "mobile/setPacVerificationError",
  (configId, error) => ({
    payload: { configId, error },
  })
);

/**
 * Set discount percentage
 * @param {string} discountType
 * @param value
 * @param configIds
 * @returns {{type, value: *, configurationIds: *}}
 */
export const setMobileProductDiscount = createAction(
  "mobile/setMobileProductDiscount",
  (discountType: string | null, value: number | null, configIds: number[]) => ({
    payload: { configIds, discountType, value },
  })
);

/**
 * Verify the PAC codes for specified mobile configurations sequentially.
 * Checks if a port date has already been populated first, which would indicate successful check has already happened.
 * Shows alert message if PAC code or mobile number is missing, or if validation fails on either of these fields.
 *
 * @param {array} configIds
 * @returns {function(*, *)}
 */
export const verifyPacCodes = createAsyncThunk(
  "mobile/verifyPacCodes",
  async (configIds: number[], { getState, dispatch }: any) => {
    const configs = getState().mobile.configs;
    configIds.forEach((configId) => {
      if (!configs[configId].properties.pac_expiry_date) {
        dispatch(fetchCheckPacCode(configId));
      }
    });
  }
);

export const fetchCheckPacCode = createAsyncThunk(
  "mobile/fetchCheckPacCode",
  async (configId: number, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as any;
    const { mobile_number, pac } = state.mobile.configs[configId]?.properties;
    const product = getProductDataForConfig(state, configId);
    const maxRetries = 5;
    let retries = 0;

    while (retries < maxRetries) {
      try {
        const checkPacCodeResponse = await MobileAPI.checkPacCode(
          mobile_number,
          (pac || "").toUpperCase()
        );

        if (
          checkPacCodeResponse.status === "error" ||
          checkPacCodeResponse.result.mnp_status === "error"
        )
          throw checkPacCodeResponse;

        const newSupplier = product.mobile.product_component_data.supplier;
        const { no_code, sp_code } = checkPacCodeResponse?.result;
        const acquisitionMethodResponse = await MobileAPI.acquisitionMethod(
          newSupplier.toLowerCase(),
          no_code,
          sp_code
        );

        if (!acquisitionMethodResponse?.acquisition_method) {
          throw new Error(
            "Missing or unexpected acquisition method API response"
          );
        }

        dispatch(
          receivePacVerification(
            configId,
            checkPacCodeResponse,
            acquisitionMethodResponse.acquisition_method
          )
        );

        return;
      } catch (e: any) {
        if (retries === 4 || e.reason_code !== "ERROR_UNKNOWN") {
          dispatch(setPacVerificationError(configId, e));
          console.error(e);
          return rejectWithValue(e);
        }
        retries++;
        await delay(1000);
      }
    }
  }
);

/**
 * Resets the PAC state back to the default state, so that a new PAC code can be entered.
 *
 * @param {array} configIds
 * @return {function(*, *)}
 */
export const resetPacCodes = createAsyncThunk(
  "mobile/resetPacCodes",
  async (configIds: number[], { getState, dispatch }) => {
    configIds.forEach((configId) => {
      dispatch(resetPacState({ configId: configId }));
    });
  }
);

export const resetPacState = createAction<{ configId: number }>(
  "mobile/resetPacState"
);

/**
 * Verify a STAC code (new mobile connections). STAC is used to stop the billing from an old provider.
 * @param configIndex
 * @returns {{configId: *, type: string}}
 */
export const verifyStacCode = createAsyncThunk(
  "mobile/verifyStacCode",
  async ({ configIndex }: { configIndex: number }, { getState }) => {
    const state = getState() as any;
    const { stac, old_mobile_number } =
      state.mobile.configs[configIndex].properties;

    const response = await MobileAPI.checkStacCode(
      (stac || "").toUpperCase(),
      old_mobile_number
    );

    return { response, configIndex };
  }
);

/**
 * Resets the STAC state back to the default state, so that a new STAC code can be entered.
 *
 * @param {array} configIds
 * @return {function(*, *)}
 */
export const resetStacCodes = createAsyncThunk(
  "mobile/resetStacCodes",
  async (configIds: number[], { getState, dispatch }) => {
    configIds.forEach((configId) => {
      dispatch(resetStacState({ configId: configId }));
    });
  }
);

export const resetStacState = createAction<{ configId: number }>(
  "mobile/resetStacState"
);
/**
 * Verify SIM serials for specified mobile configurations.
 *
 * @param {array} configIds
 * @returns {function(*, *)}
 */
export const verifySimSerials = createAsyncThunk(
  "mobile/verifySimSerials",
  async (configIds: number[], { dispatch }: any) => {
    configIds.forEach((configId) => {
      dispatch(fetchSimVerification(configId));
    });
  }
);

/**
 * Check a SIM serial # is valid
 * @param configId
 * @returns {IterableIterator<*>}
 */
export const fetchSimVerification = createAsyncThunk(
  "mobile/fetchSimVerification",
  async (configId: any, { getState }: any) => {
    const { sim_buffer_serial } = getConfigProperties(getState(), configId);

    const { supplier } = getProductComponentData(getState(), configId);

    const response = await SIMsAPI.isValidSIMNumber(
      sim_buffer_serial,
      supplier
    );
    return response;
  }
);

/**
 * Get Mobile Bars Compatibility
 * Looks at current bars selection and sees what else can be selected according to the endppint.
 * @param configId
 * @returns {{configId: *, type: string}}
 */
export const fetchBarsCompatibility = createAsyncThunk(
  "mobile/fetchBarsCompatibility",
  async (configId: any, { getState }: any) => {
    const { properties } = getState().mobile.configs[configId];

    const { supplier } = getProductComponentData(getState(), configId);

    const currentBars = Object.keys(properties).filter(
      (k) => k.includes("bar_") && properties[k] == 1 // eslint-disable-line eqeqeq
    );
    const response = await MobileProvisioningAPI.BarsCompatibilitiesForNetwork(
      supplier,
      currentBars
    );

    return response;
  }
);

/**
 * Set delivery address type
 * @param addressType {addressTypes}
 * @param configIndexes {number|array}
 * @returns {{addressType: addressTypes, type: string}}
 */
export const setDeliveryAddressType = createAction(
  "mobile/setDeliveryAddressType",
  (addressType: string, configIndexes: number[]) => ({
    payload: { addressType, configIndexes },
  })
);

/**
 * Set delivery address ID
 * @param addressId {string}
 * @param configIndexes {number|array}
 * @returns {{addressId: string, type: string}}
 */
export const setDeliveryAddressId = createAction(
  "mobile/setDeliveryAddressId",
  (addressId: string, configIndexes: number[]) => ({
    payload: { addressId, configIndexes },
  })
);

export const setAdditionalBundle = createAction(
  "mobile/setAdditionalBundle",
  (bundleId: string, configId: number) => ({
    payload: { bundleId, configId },
  })
);

export const requestOrderProduct = createAction<{
  configIndex: number;
  isUpdate: boolean;
}>("mobile/requestOrderProduct");

export const receiveOrderProduct = createAction<{
  configIndex: number;
  response: any;
}>("mobile/receiveOrderProduct");

export const requestOrderBoltOn = createAction<{
  productId: any;
}>("mobile/requestOrderBoltOn");

export const receiveOrderBoltOn = createAction<{
  productId: any;
  response: any;
}>("mobile/receiveOrderBoltOn");

export const requestHardwareCredit = createAction<{ isUpdate: any }>(
  "mobile/requestHardwareCredit"
);

export const receiveHardwareCredit = createAction<{
  response: any;
}>("mobile/receiveHardwareCredit");

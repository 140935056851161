import { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { format } from "date-fns";

import { STAC } from "../constants";
import {
  fetchBarsCompatibility,
  fetchCheckPacCode,
  fetchMobileSearch,
  fetchSimVerification,
  receivePacVerification,
  setProductQuantity,
  verifyStacCode,
} from "../actions";
import { newConfig } from "../defaults";

import { getMinimumPortDate } from "../selectors/productConfig";
import { DC_DATE_FORMAT } from "../../../shared/utils/date";

export const newConfigsExtraReducers = (builder: any) => {
  builder
    .addCase(fetchMobileSearch.pending, (state: any, action: any) => {
      state.mobileSearch.fetching = true;
    })
    .addCase(
      fetchMobileSearch.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        state.mobileSearch.fetching = false;
        state.mobileSearch.response = action.payload;
      }
    )
    .addCase(
      setProductQuantity.fulfilled,
      (
        state: any,
        action: PayloadAction<{
          quantity: number | undefined;
          productId: string;
          settings: any;
          isOneMonthContract?: number | undefined;
        }>
      ) => {
        const { quantity, productId, isOneMonthContract, settings } =
          action.payload;
        const newQty = quantity;
        // active configs for productId
        let productConfigs: any = state.configs.filter(
          (c: any) => c.productId === productId && !c.resignId
        );

        // rest of configs
        const restConfigs: any = state.configs.filter(
          (c: any) => c.productId !== productId || c.resignId
        );

        // Get Actual Quantity of products
        const actualQty = productConfigs.length;

        // Remove configs if desired quantity is less
        if (newQty !== undefined && newQty < actualQty) {
          productConfigs = productConfigs.filter(
            (_: any, i: number) => i < newQty
          );
        }

        // Add configs if desired quantity is more
        if (newQty && newQty > actualQty) {
          let newConfigsLength = newQty - actualQty;

          while (newConfigsLength) {
            productConfigs.push(
              newConfig(state, productId, settings, isOneMonthContract)
            );
            newConfigsLength -= 1;
          }
        }
        const configs = [...restConfigs, ...productConfigs];
        state.configs = configs;
      }
    )
    .addCase(fetchCheckPacCode.pending, (state: any, action: any) => {
      const configId = action.meta.arg;
      state.configs[configId].pacCodeCheck = { fetching: true, error: false };
    })
    .addCase(fetchCheckPacCode.rejected, (state: any, action: any) => {
      const configId = action.meta.arg;
      state.configs[configId].pacCodeCheck = { fetching: false, error: true };
    })
    .addCase(receivePacVerification, (state: any, action: any) => {
      const { configId, response, verifiedAcquisitionMethod } = action.payload;
      let pac_expiry_date = _.get(response, "result.pac_expiry_date", "");
      if (pac_expiry_date === "n/a") pac_expiry_date = "";
      state.configs[configId].pacCodeCheck = {
        fetching: false,
        response,
        error: false,
        verifiedAcquisitionMethod,
      };
      state.configs[configId].properties = {
        ...state.configs[configId].properties,
        pac_expiry_date,
      };
      state.configs[configId].validation = {
        ...(pac_expiry_date && { pac_expiry_date: false }),
      };
    })
    .addCase(verifyStacCode.pending, (state: any, action: any) => {
      const { configIndex } = action.meta.arg;

      state.configs[configIndex].stacCodeCheck = {
        fetching: true,
      };
    })
    .addCase(verifyStacCode.fulfilled, (state: any, action: any) => {
      const { response, configIndex } = action.payload;
      state.configs[configIndex].stacCodeCheck = {
        response,
        fetching: false,
      };
      state.configs[configIndex].properties = {
        ...state.configs[configIndex].properties,
        stac_expiry_date: _.get(response, "result.pac_expiry_date"), // Why is this pac not stac?!
        activation_date: format(getMinimumPortDate(STAC), DC_DATE_FORMAT),
      };
    })
    .addCase(fetchSimVerification.pending, (state: any, action: any) => {
      const configId = action.meta.arg;
      state.configs[configId].simValidCheck = { fetching: true };
    })
    .addCase(fetchSimVerification.fulfilled, (state: any, action: any) => {
      const configId = action.meta.arg;
      const response = action.payload;
      state.configs[configId].simValidCheck = {
        fetching: false,
        response,
      };
    })
    .addCase(fetchBarsCompatibility.pending, (state: any, action: any) => {
      const configId = action.meta.arg;
      state.configs[configId].barsCompatibility = {
        fetching: true,
      };
    })
    .addCase(fetchBarsCompatibility.fulfilled, (state: any, action: any) => {
      const configId = action.meta.arg;
      const response = action.payload;
      state.configs[configId].barsCompatibility = {
        fetching: false,
        response,
      };
    })
    .addCase(fetchBarsCompatibility.rejected, (state: any, action: any) => {
      const configId = action.meta.arg;
      state.configs[configId].barsCompatibility = {
        fetching: false,
        error: action.error.message,
      };
    });
};
